import langEn from "../../languages/en";
import langDe from "../../languages/de";

import PropTypes          from 'prop-types';
import React, {Component} from "react";
import Translator         from "../../components/Translator";
import Help               from "../Help/Help";
import Privacy            from "../Privacy/Privacy";
import {AppConsumer}      from "../../contexts/AppContext";

let counterpart = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);

class Header extends Component
{

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor(props)
    {
        super(props);

        this.state = {
            helpScreenIsVisible:    false,
            privacyScreenIsVisible: false
        };

        this.changeHelpViewState    = this.changeHelpViewState.bind(this);
        this.changePrivacyViewState = this.changePrivacyViewState.bind(this);
    }

    getIcon()
    {
        if (this.state.helpScreenIsVisible && !this.state.privacyScreenIsVisible) {
            return (<li className="__help">
                <div onClick={this.changeHelpViewState}>
                    <span><i className="icon icon-stayery-closer"/></span>
                </div>
            </li>)
        }
        if (this.state.privacyScreenIsVisible) {
            return (<li className="__help">
                <div onClick={this.changePrivacyViewState}>
                    <span><i className="icon icon-stayery-closer"/></span>
                </div>
            </li>)
        } else {
            return (<li onClick={this.changeHelpViewState} className="__help">
                <i className="icon icon-stayery-help"/>
            </li>)
        }
    }


    languageMenu()
    {
        return (
            <div className="navbar-language">
                <AppConsumer>
                    {({ lang, changeLanguage }) => (
                        <ul className="language-menu">
                            {!(this.state.privacyScreenIsVisible) && <span
                                onClick={this.changePrivacyViewState}
                            >{lang === 'de' ? 'Datenschutz' : 'Privacy Policy'}</span>}
                            <li className="__spacer"/>
                            <li className={lang === 'de' ? 'active' : ''}>
                                <span onClick={() => changeLanguage('de')}>
                                    <span>DE</span>
                                </span>
                            </li>
                            <li className="__spacer">/</li>
                            <li className={lang === 'en' ? 'active' : ''}>
                                <span onClick={() => changeLanguage('en')}>
                                    <span>EN</span>
                                </span>
                            </li>
                            {this.getIcon()}
                        </ul>
                    )}
                </AppConsumer>
            </div>
        );
    }

    backButton()
    {
        const onClick = () => {
            if (this.props.onBack) {
                this.props.onBack();
            }
            this.context.router.history.goBack();
        };
        return !this.state.helpScreenIsVisible ? (<div className="back-button"
                                                       hidden={!this.props.backButtonVisible}
                                                       onClick={onClick}>
                <i className="icon icon-stayery-arrow-l"/><Translator content={"start.goback"}/>
            </div>) : false;
    }

    changeHelpViewState()
    {
        let newState = !this.state.helpScreenIsVisible;
        this.setState({helpScreenIsVisible: newState});

        if (newState) {
            this.setState({privacyScreenIsVisible: false});
        }
    }

    changePrivacyViewState()
    {
        let newState = !this.state.privacyScreenIsVisible;
        this.setState({privacyScreenIsVisible: newState});

        if (newState) {
            this.setState({helpScreenIsVisible: false});
        }
    }

    getPageTitle()
    {
        let title = counterpart.translate(this.props.headerTitle)
        if (this.state.helpScreenIsVisible) {
            title = counterpart.translate('pageTitles.helppage')
        } else if (this.state.privacyScreenIsVisible) {
            title = counterpart.translate('pageTitles.privacy')
        }
        return title
    }

    render()
    {
        return (<header className="app-header">
            <div className="container">
                <div className="navigation-wrapper">
                    <div className="navigation-left">
                        {!(this.state.privacyScreenIsVisible) ? this.backButton() : null}
                        <h1 className="header-title"
                            hidden={this.props.headerTitle === ''}>
                            {this.getPageTitle()}
                        </h1>
                    </div>
                    {(!this.props.deactivateLanguageSwitcher) ? (
                        <div className="navigation-right">{this.languageMenu()}</div>) : false}
                </div>
            </div>
            <Help showHelp={this.state.helpScreenIsVisible}/>
            <Privacy show={this.state.privacyScreenIsVisible}/>
        </header>);
    }
}

export default Header;
