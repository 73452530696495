import React from 'react';
import counterpart from "counterpart";
import i18next     from "i18next";

export const DEFAULT_LANG = 'de';

export const AppContext = React.createContext({
    lang: DEFAULT_LANG,
    changeLanguage: (lang) => {},
});

export class AppProvider extends React.Component {
    constructor(props)
    {
        super(props);

        this.changeLanguage = (lang) => {
            counterpart.setLocale(lang);
            i18next.changeLanguage(lang);

            this.setState(state => ({ lang }));
        }

        this.state = {
            lang: DEFAULT_LANG,
            changeLanguage: this.changeLanguage,
        }
    }

    render()
    {
        return (
            <AppContext.Provider value={this.state}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export const AppConsumer = AppContext.Consumer;
