import React, {Component} from 'react';
import {Route, Switch}    from 'react-router-dom';
import PropTypes          from 'prop-types';
import packageJson        from '../package.json';
import Main               from './pages/Main/Main';
import LostCard           from './pages/Lostcard/LostCard';

import './App.css';

import Checkout             from "./pages/Checkout/Checkout";
import Login                from "./pages/Checkin/Login";
import Address              from "./pages/Checkin/Address";
import Signature            from "./pages/Checkin/Signature";
import Citytax              from "./pages/Checkin/Citytax";
import Corona               from "./pages/Checkin/Corona";
import RoomInformation      from "./pages/Checkin/RoomInformation";
import Keycard              from "./pages/Keycard/Keycard";
import {Redirect}           from "react-router";
import Thankyou             from "./pages/Thankyou/Thankyou";
import ErrorHandler         from "./pages/ErrorHandler/ErrorHandler";
import TerminalVerification from "./pages/TerminalVerification/TerminalVerification";

import * as Sentry        from '@sentry/browser';
import ConsultFrontOffice from "./pages/Checkin/Consultfrontoffice";

import {isSafari}    from "react-device-detect";
import {theHistory}  from "./index";
import moment        from "moment";
import Privacy       from "./pages/Privacy/Privacy";
import {AppProvider} from "./contexts/AppContext";

class App extends Component
{

    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    timeoutIDRedirect;
    timeoutIDMessage;

    constructor(props)
    {
        super(props);

        this.state = {
            updateRequired:               false,
            redirect:                     false,
            terminalIsInactive:           false,
            showInactivityMessage:        false,
            showTerminalVerificationPage: false,
            reservation:                  null,
            helpScreenIsVisible:          false,
            privacyScreenIsVisible:       false
        };

        theHistory.listen(args => {

            let storageKey   = "test";
            let history      = localStorage.getItem(storageKey)
            let terminalCode = localStorage.getItem('verificationCode');

            let h_Array = [];
            if (history !== null) {
                h_Array = JSON.parse(history);
            }
            h_Array.push({
                             pathname: args.pathname,
                             action:   args.action,
                             time:     moment().format()
                         });
            if (args.pathname === "/main") {
                if (h_Array.length === 2 && h_Array[0].pathname === '/' && h_Array[1].pathname === '/main') {
                    // probably reload, do nothing
                } else {
                    Sentry.setContext("history", {...h_Array});
                    Sentry.captureMessage("Pagehistory: " + terminalCode + " (" + h_Array.length + ")");
                    h_Array = [];
                }
            }

            history = JSON.stringify(h_Array);

            localStorage.setItem(storageKey, history)
        });

        this.startTimer                  = this.startTimer.bind(this);
        this.onUserActivity              = this.onUserActivity.bind(this);
        this.goInactive                  = this.goInactive.bind(this);
        this.showInactivityMessage       = this.showInactivityMessage.bind(this);
        this.checkStorageForTerminalCode = this.checkStorageForTerminalCode.bind(this);
    }

    setReservation = (reservation) => {
        this.setState({reservation})
    };

    componentWillReceiveProps()
    {
        this.resetDefaultStates(true);
    }

    componentWillUpdate()
    {
        let pathname = this.context.router.history.location.pathname;
        if (this.state.updateRequired && (pathname === "/" || pathname === "/main")) {
            this.forceUpdate(this.appUpdate)
        }
    }

    componentDidMount()
    {
        this.checkStorageForTerminalCode();
        let app = this;
        window.addEventListener('mousedown', this.onUserActivity, false);

        this.props.apiClient.setEventListener('terminalCodeInvalid', function() {
            localStorage.removeItem('verificationCode');
            app.checkStorageForTerminalCode();
        }.bind(this));

        this.props.apiClient.setEventListener('updateRequired', function() {
            if (!app.state.updateRequired) {
                app.setState({updateRequired: true});
                let pathname = app.context.router.history.location.pathname;
                if (pathname === "/" || pathname === "/main") {
                    app.forceUpdate(app.appUpdate)
                }
            }
        });

        this.startTimer();
    }

    appUpdate = () => {
        this.setState({updateRequired: false}, () => {
            this.props.apiClient.verifyTerminalCode(localStorage.getItem('verificationCode')).then(response => {
                const hotline = response?.hotline ?? '';
                if (hotline !== '') {
                    localStorage.setItem('hotline', hotline);
                }
                const citytaxMode = response?.citytaxMode ?? '';
                if (citytaxMode !== '') {
                    localStorage.setItem('citytaxMode', citytaxMode);
                }
                const enableCheckout = response?.enableCheckout ?? '';
                if (enableCheckout !== '') {
                    localStorage.setItem('enableCheckout', enableCheckout);
                }
                window.location.reload(true);
            });
        })
    };

    componentDidCatch(error, errorInfo)
    {
        this.setState({error});
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    resetDefaultStates(bool)
    {
        let state = {
            redirect:              false,
            showInactivityMessage: false
        };
        if (typeof bool === 'boolean' && bool) {
            state.reservation = null;
        }

        if (this.state.redirect || this.state.showInactivityMessage) {
            this.setState(state);
        }
    }

    startTimer()
    {
        this.resetDefaultStates();
        this.timeoutIDRedirect = window.setTimeout(this.goInactive, 90000);
        this.timeoutIDMessage  = window.setTimeout(this.showInactivityMessage, 75000);
    }

    isHomeScreen = () => {
        let pathname = this.context.router.history.location.pathname;
        return pathname === "/" || pathname === "/main";
    };

    onUserActivity()
    {
        window.clearTimeout(this.timeoutIDRedirect);
        window.clearTimeout(this.timeoutIDMessage);
        this.startTimer();
    }

    goInactive()
    {
        if (!this.isHomeScreen()) {
            try {
                const uuid = this.context.router.history?.location?.state?.uuid ?? 'NaN';
                this.props.apiClient.postAnalyticsData(uuid, 'goInactive');
            } catch (e) {
                console.error(e);
            }
            this.setState({redirect: true});
        } else {
            this.startTimer();
        }
    }

    showInactivityMessage()
    {
        if (!this.isHomeScreen()) {
            document.activeElement.blur();
            this.setState({showInactivityMessage: true});
        }
    }

    checkStorageForTerminalCode()
    {
        let terminalCode = localStorage.getItem('verificationCode');

        if (terminalCode) {
            this.props.apiClient.setTerminalCode(terminalCode);
            this.setState({showTerminalVerificationPage: false});
        } else {
            this.setState({showTerminalVerificationPage: true});
        }
    }

    render()
    {
        if (this.state.redirect) {
            return (<Redirect refresh={true} to="/" />);
        }

        if (isSafari) {
            document.body.classList.add('isSafari')
        }

        return (<div className="App">
                <AppProvider>
                    {this.state.showTerminalVerificationPage ? (<TerminalVerification
                        apiClient={this.props.apiClient}
                        checkStorageForTerminalCode={this.checkStorageForTerminalCode}
                    />) : false}
                    {this.state.showInactivityMessage ? <ErrorHandler messageType="warning" /> : false}
                    {/*<Route path='/' exact component={Start}/>*/}
                    <Route path="/" exact render={(props) => (<Main
                        {...props}
                        apiClient={this.props.apiClient}
                    />)} />
                    <Route hist path="/:locale">
                        <Switch>
                            <Route path="/:locale/checkout"
                                   render={(props) => (<Checkout
                                       {...props}
                                       apiClient={this.props.apiClient}
                                   />)} />
                            <Route path="/:locale/checkin/login"
                                   render={(props) => (<Login
                                       {...props}
                                       reservation={this.state.reservation}
                                       setReservation={this.setReservation}
                                       apiClient={this.props.apiClient} />)} />
                            <Route path="/:locale/checkin/address"
                                   render={(props) => <Address {...props} reservation={this.state.reservation}
                                                               apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/checkin/corona"
                                   render={(props) => <Corona {...props} reservation={this.state.reservation}
                                                              apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/checkin/signature"
                                   render={(props) => <Signature {...props} reservation={this.state.reservation}
                                                                 apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/checkin/citytax"
                                   render={(props) => <Citytax {...props} reservation={this.state.reservation}
                                                               apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/checkin/roominformation"
                                   render={(props) => <RoomInformation {...props} reservation={this.state.reservation}
                                                                       apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/checkin/consultfrontoffice"
                                   render={(props) => <ConsultFrontOffice {...props}
                                                                          reservation={this.state.reservation}
                                                                          apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/checkin/keycard"
                                   component={(props) => <Keycard {...props} reservation={this.state.reservation}
                                                                  apiClient={this.props.apiClient} />} />


                            <Route path="/:locale/lostcard"
                                   render={(props) => <LostCard {...props} reservation={this.state.reservation}
                                                                apiClient={this.props.apiClient} />} />
                            <Route path="/:locale/keycard"
                                   component={(props) => <Keycard {...props} reservation={this.state.reservation}
                                                                  apiClient={this.props.apiClient} />} />

                            <Route path="/:locale/thankyou" component={Thankyou} />

                            <Route path="/:locale/privacy" component={Privacy} />
                        </Switch>
                    </Route>
                    <div style={{
                        position:   'absolute',
                        right:      0,
                        bottom:     0,
                        opacity:    0.1,
                        userSelect: "none"
                    }}>{packageJson.version}</div>
                </AppProvider>
            </div>);
    }
}

export default App;
