import React, {Component} from 'react';
import {Link}             from 'react-router-dom';
import Translator         from "../../components/Translator";
import Header             from "../Header/Header";

import {v4 as generateUUID} from "uuid";

import checkInImg     from '../../styles/images/checknewscreen-in.jpg';
import checkOutImg    from '../../styles/images/checkoutLabel.jpg';
import neueKeycardImg from '../../styles/images/Stayery-SCT-Lost-Key-Card.jpg';
import {AppContext, DEFAULT_LANG} from "../../contexts/AppContext";

class Main extends Component
{
    constructor(props)
    {
        super(props);
    }

    componentDidMount()
    {
        this.context.changeLanguage(DEFAULT_LANG);
    }

    gotoCheckout()
    {
        const uuid = generateUUID();

        try {
            this.props.apiClient.postAnalyticsData(uuid, 'go to /main/checkout/');
        } catch (e) {
            console.error(e);
        }

        this.props.history.push({pathname: '/main/checkout/'}, {uuid: uuid})
    }
    render()
    {
        let checkoutBtn = null;
        if (localStorage.getItem('enableCheckout') === "true") {
            checkoutBtn = (<div className="col-12 checkout-btn-wrap">
                <button
                    onClick={() => this.gotoCheckout()}
                    className="btn btn-transparent btn-uppercase btn-space float-right">
                    <Translator content={"main.checkout"}/>
                    <i className="pl-2 icon icon-stayery-arrow-r"/>
                </button>
            </div>);
        }

        return (<div className="page-main">
            <div className="page-inner-wrapper">
                <Header headerTitle="pageTitles.mainPage"/>
                <div className="content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="bodytext-app _main-page-text">
                                    <Translator content={"main.description"}/>
                                </div>
                            </div>
                            <div className="flipkachel-wrapper">
                                <Link style={{textDecoration: 'none'}} to={'/main/checkin/login'}>
                                    <div className="stayery-flipkachel _check-in">
                                        <div className="flipkachel-inner-wrap">
                                            <div className="__header">
                                                <Translator
                                                    content={"main.checkinLabel"}
                                                />
                                            </div>
                                            <div className="__image">
                                                <img src={checkInImg} alt="Check-in"/>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="__left">
                                                        <Translator content={"main.shop"}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="__right">
                                                        <i className="icon icon-stayery-arrow-r"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link style={{textDecoration: 'none'}} to={"/main/checkout"}>
                                    <div className="stayery-flipkachel _check-out">
                                        <div className="flipkachel-inner-wrap">
                                            <div className="__header">
                                                <Translator
                                                    content={"main.checkoutLabel"}
                                                />
                                            </div>
                                            <div className="__image">
                                                <img src={checkOutImg} alt="checkout"/>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="__left">
                                                        <Translator content={"main.checkoutbtn"}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="__right">
                                                        <i className="icon icon-stayery-arrow-r"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link style={{textDecoration: 'none'}} to={'/main/lostcard'}>
                                    <div className="stayery-flipkachel _keycard">
                                        <div className="flipkachel-inner-wrap">
                                            <div className="__header">
                                                <Translator
                                                    content={"main.neuekeycardLabel"}
                                                />
                                            </div>
                                            <div className="__image">
                                                <img src={neueKeycardImg} alt="Check-out"/>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="__left">
                                                        <Translator content={"main.lostcard"}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="__right">
                                                        <i className="icon icon-stayery-arrow-r"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

Main.contextType = AppContext;

export default Main;
