const langEn = {
    thisIso:            'en-EN',
    datePlaceholder:    'DD.MM.YYYY',
    date:               {
        day:   'D',
        month: 'M',
        year:  'Y'
    },
    pageTitles:         {
        "default":             "Stayery.",
        "mainPage":            "GOOD TO\nSEE YOU...",
        "checkoutPage":        "SAY GOODBYE.",
        "loginPage":           "Login.",
        "addressPage":         "Confirm your personal\ndata.",
        "signaturePage":       "and now. signature please.",
        "citytaxPage":         "Serious Business or just for fun?",
        coronaPage:            'COVID-19 Regulations.',
        "roominformationPage": "Checked In.\nYour apartment\nis ready for you.",
        "consultfrontoffice":  "Checked in.\nAnd now?",
        "keycardPage":         "Yesssss,\nyou did it.",
        "lostcardPage":        "Verification.",
        "thankyouPage":        "Thanks.",
        "helppage":            "Do you\nneed help?",
        "verificationCode":    "Bitte geben Sie den Verifizierungscode\nfür dieses Gerät ein",
        "privacy":             "Privacy Policy"
    },
    buttons:            {
        confirmInformations: 'Confirm data',
        confirm:             'Confirm'
    },
    start:              {
        "startLabel": "Here you can check in\nand get your Keycard",
        "goback":     "Go back"
    },
    main:               {
        "checkinLabel":     "CHECK-IN",
        "checkoutLabel":    "CHECK-OUT",
        "neuekeycardLabel": "New Keycard",
        "lostcardLabel":    "CARD LOST",
        "description":      "...and welcome to the STAYERY. Here you can check in, \ncheck out or replace a lost keycard",
        "login":            "Sign in",
        "shop":             "LET ME IN",
        "checkoutbtn":      "LET ME OUT",
        "lostcard":         "Please save me",
        "checkout":         "Go to Check-Out"
    },
    checkin:            {
        buttons:       {
            "submit":   "Let's start",
            "getcard":  "Request card",
            "getcards": "Request cards"
        },
        text:          {
            "beforeLoginDescription":    "Here we go - you know the drill: Just enter your booking number and your last name to proceed with the check in.\n\nYou can find your booking number in the email or WhatsApp we sent you prior to your stay. If you still have difficulties with the login, call us and we will shed some light in the shadow.",
            "notforget":                 "Remember your apartment number.",
            "roomnotice":                "Take a mental note of your apartment number or a digital note on\nyour phone. Very important: don't forget it! Are you ready for the\nnext step? The ice cream cart prints your keycard - like magic!",
            "roomnoticetwoCardsAllowed": "Take a mental note of your apartment number or a digital note on\nyour phone. Very important: don't forget it! Are you ready for the\nnext step? Choose whether you need one or two keycards - like magic!"
        },
        errors:        {
            "notFound": "The booking cannot be retrieved. The following reasons are possible:\n- The name is wrong\n- The booking do not exist or  lies in the past"
        },
        "radioButton": {
            "one": "1 Card",
            "two": "2 Cards"
        }
    },
    checkout:           {

        default: {
            buttons: " I'm out!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox next to the entry and then out you go. Your invoice will be sent to you automatically by e-mail.\n\nIf you had a parking space, first drive out of the parking garage, then come back into the STAYERY and drop your transponder together with your key card in the yellow mailbox next to the entry.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },

        BRE:    {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox left of the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nIf you had a parking space, first drive out of the parking garage, then come back into the STAYERY and drop your transponder together with your key card in the yellow mailbox left of the entry.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        BER_FR: {
            buttons: "ICK MACH'N ABJANG!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox left of the entry and then out you go. Your invoice will be sent to you automatically via e-mail." +
                         "\n\nIf you had a parking space, first drive out of the parking garage, then come back into the STAYERY and drop your transponder together with your key card in the yellow mailbox left of the entry." +
                         "\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        BIE_HB: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox left of the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nIf you have a parking space, go to the garage entrance in the basement and drop your keycard in the yellow letterbox. You can exit the parking garage without card.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        CGN_EF: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the white letterbox next to the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nIf you have a parking space, go to the garage entrance in the basement and drop your keycard in the yellow letterbox. You can exit the parking garage without card.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        CGN_WS: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox next to the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nIf you have a parking space, simply drop your keycard in the yellow letterbox. You can exit the courtyard without card.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        HBR_AW: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the white letterbox next to the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nIf you have a parking space, go to the garage entrance in the basement and drop your keycard in the yellow letterbox. You can exit the parking garage without card.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!!"
        },
        FRA_SH: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox next to the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nIIf you have a parking space, go to the garage entrance in the basement and drop your keycard in the yellow letterbox. You can exit the parking garage without card.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        WOB_SW: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox in the hallway to the elevator and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        },
        GTL_LW: {
            buttons: "I’M OUT!",
            text:    "Time to go? Our check-out is easy peasy. Simply drop your keycard in the yellow letterbox next to the entry and then out you go. Your invoice will be sent to you automatically via e-mail.\n\nWe hope you had a very, very extraordinary stay with us. See you next time!"
        }
    },
    consultfrontoffice: {
        text: {
            headline: "You are ready.\nYour apartment is not.",
            text:     "You are either too early or we are too late. Please contact your\nhosts to move into your apartment. If they are not on site, please\ncall them:"
        }
    },
    address:            {
        "information": "Your personal data is incorrect? Then simply make the necessary adjustments in the fields above. Cheers to that!",
        errors:        {
            "msg1":      "Checkin error. The following reasons are possible:\n- The check-in can only be carried out on the day of arrival\n- Your booking is in the past",
            "outOfTime": "Checkin error.\n Check-In is only possible from the day of arrival to the booked departure time the latest.",
            "farFuture": "Your booking is more than 24 hours in the future. Please come back later to check in.",
            "notFound":  "That's weird! We can't find your booking. Try again! Otherwise please call us and we solve your problem.",
            "occupied":  "Our mistake. Seems like your apartment is not ready yet. Please find our hosts!",
            "inHouse":   "Checkin error. You already checked in. You can get a new keycard through the menue item 'New Keycard'.",
            wrongHotel:  {
                default: {
                    'title': 'OOOOOOPS, WRONG STAYERY...',
                    'body':  "You are in the STAYERY <b>{{terminalPropertyName}}</b> but you have booked for <b>{{reservationPropertyName}}</b>. Please check your booking confirmation to find the right address. Otherwise give us a call and we'll solve the problem."
                }
            }
        }
    },
    signature:          {
        "text":        "Please put your beautiful signature in the field below. According to the German Meldegesetz (Registration Law) this is a legal requirement.",
        "information": "According to the German Registration Law STAYERY is obligated to present a registration form filled in and signed by the guest. The accuracy of the personal data must be confirmed by the guest with signature.",
        "button":      "next"
    },
    citytax:            {
        business:       "Business",
        leisure:        "Leisure",
        ready:          "Check-In",
        confirmPayment: "Check-In",
        nextButton:     "next",
        text:           {
            headline:          "Let us know if you are travelling for leisure or business.",
            headline_business: "You are travelling for business. We will therefore issue the invoice to your company. Please complete the invoice details if they have not yet been filled in.",
            headline_leisure:  "You are in the city for leisure purposes. We will therefore issue your invoice to your home address."
        },
        textLeisure:    {
            BER_FR: {
                1: "A city tax is payable for leisure and business stays. This tax is paid to the city of Berlin.\n\nIf you are travelling for business and made your booking before 1\xa0April\xa02024, your booking is not subject to city\xa0tax.",
                2: "In this case you have to pay an extra amount of 5% to 7.5% of your apartment price. But only for the first 21 nights."
            },
            BIE_HB: {
                1: "A city tax is payable for leisure stays. This tax is paid to the city of Bielefeld.",
                2: "In this case you have to pay an extra amount of 5% of your apartment price. But only for the first 21 nights."
            },
            WOB_SW: {
                1: "A city tax is payable for leisure stays. This tax is paid to the city of Wolfsburg.",
                2: "In this case you have to pay an extra amount of 5% of your apartment price. But only for the first 21 nights."
            },
            CGN_EF: {
                1: "A city tax is payable for leisure and business stays. This tax is paid to the city of Cologne.",
                2: "In this case you have to pay an extra amount of 5% of your apartment price."
            },
            CGN_WS: {
                1: "A city tax is payable for leisure and business stays. This tax is paid to the city of Cologne.",
                2: "In this case you have to pay an extra amount of 5% of your apartment price."
            },
            FRA_SH: {
                1: "A city tax is payable for leisure and business stays. This tax is paid to the city of Frankfurt.",
                2: "In this case you have to pay an extra amount of 2€ per person per night to your apartment price."
            },
            HBR_AW: {
                1: "A city tax is payable for leisure and business stays. This tax is paid to the city of Bremen.",
                2: "In this case you have to pay an extra amount of 5% of your apartment price. But only for the first 7 nights."
            },
            BRE:    {
                1: "A city tax is payable for leisure and business stays. This tax is paid to the city of Bremen.",
                2: "In this case you have to pay an extra amount of 5% of your apartment price. But only for the first 7 nights."
            },
            3:      "You won’t be able to avoid it but luckily you also don’t need to act yourself. Your credit card is automatically charged after you confirm it."
        }
    },
    corona:             {
        DEFAULT: {
            intro:   'Due to the current government regulations (Coronaschutzverordnung) there is a corona testing obligation (3G-rule) in place for overnight stays at hotels and other accommodations. Please confirm, that you provide evidence for one of the following:',
            impfung: 'Proof of vaccination confirming complete vaccine protection',
            genesen: 'Proof of recovery from COVID-19',
            test:    'Negative test result',
            footer:  'Confirm your selection in the next step with your signature.'
        }
    },
    keycard:            {
        "title":                "Go get your keycard.",
        "title_2":              "Go get your keycards.",
        "checkinDescription":   "Your keycard is hot off the press. Take it out of the keycard tray (look at the height of your knees). \nIf you have any questions, your hosts will be happy to help. If they are not on site, give them a call:",
        "checkinDescription_2": "Your keycards is hot off the press. Take it out of the keycard tray (look at the height of your knees). \nIf you have any questions, your hosts will be happy to help. If they are not on site, give them a call:",
        "button":               "OK, I GOT IT!"
    },
    lostcard:           {
        buttons: {
            "submit": "Request card"
        },
        text:    {
            "description": "No keycard? No problem. It's easy. Simply enter your details and you will receive your new card immediately. Of course, everyone traveling in pairs will receive two keycards. If you need help, just give us a call.",
            "ctaNote":     "Note:",
            "cta":         "As soon as your new keycard is in use, all previous cards will be deactivated automatically."
        },
        errors:  {
            "notFound": "Weird. Your reservation cannot be retrieved. Did you enter the correct data? Try again! Otherwise please call us and we shed light on the situation"
        }
    },
    roominformation:    {
        errors: {
            "createcard": "An error occurred while creating the card. Please try again.",
            "4096":       'Ouch! Seems like your card got stuck. Please move to the other terminal and try using „New Keycard".',
            "4098":       'Ouch! Something went wrong. Please move to the other terminal and try using „New Keycard".',
            "4099":       'Ouch! Seems like your card got stuck. Please move to the other terminal and try using „New Keycard".',
            "4100":       'Ouch! Something went wrong. Please move to the other terminal and try using „New Keycard".',
            "4101":       'Ouch! Something went wrong. Please move to the other terminal and try using „New Keycard".',
            "4103":       'Ouch! Something\'s wrong with this terminal. Please move to the other terminal and try using „New Keycard".',
            "8194":       'Ouch! Seems that we ran out of cards. Move to the other terminal and try using „New Keycard".'
        }
    },
    help:               {
        "text":   "If you got any questions or run into problems,\nplease contact one of our hosts. If they are not\non site, give us a call.",
        "number": "+49 (0) 30 991 916 510"
    },
    verificationCode:   {
        buttons: {
            "submit": "send"
        }
    },
    fields:             {
        "title":       "Your personal registration data",
        "bookingId":   "Booking number",
        "lastName":    "Surname",
        "firstName":   "First name",
        "birthDate":   "Date of birth",
        "email":       "E-Mail",
        "phone":       "Mobile number (for personal assistance)",
        "arrivalDate": "Date of arrival",
        "address":     "Address",
        "postalCode":  "Zipcode",
        "city":        "City",
        "country":     "Country",
        "roomnumber":  "Apartment number",
        "signature":   "Signature",
        "company":     "Company"
    },
    messages:           {
        "timeout": "WATCH IT: You will be logged out in a few seconds if there is no activity",
        "errors":  {
            "required":   "Required fields must obviously not be empty",
            "email":      "You did not enter a valid email",
            "errorTitle": " Oooooops, find the error..."
        }
    },
    countries:          {
        AF: "Afghanistan",
        AN: "Netherlands Antilles",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua and Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia",
        BQ: "Bonaire, Sint Eustatius and Saba",
        BA: "Bosnia and Herzegovina",
        BW: "Botswana",
        BV: "Bouvet Island",
        BR: "Brazil",
        IO: "British Indian Ocean Territory",
        VG: "British Virgin Islands",
        BN: "Brunei",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CX: "Christmas Island",
        CC: "Cocos (Keeling) Islands",
        CO: "Colombia",
        KM: "Comoros",
        CD: "Congo",
        CG: "Congo-Brazzaville",
        CK: "Cook Islands",
        CR: "Costa Rica",
        HR: "Croatia",
        CU: "Cuba",
        CW: "Curaçao",
        CY: "Cyprus",
        CZ: "Czech Republic",
        CI: "Côte d’Ivoire",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        FK: "Falkland Islands",
        FO: "Faroes",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GF: "French Guiana",
        PF: "French Polynesia",
        TF: "French Southern Territories",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard Island and McDonald Islands",
        HN: "Honduras",
        HK: "Hong Kong SAR of China",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Laos",
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macao SAR of China",
        MK: "Macedonia",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands",
        NC: "New Caledonia",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        KP: "North Korea",
        MP: "Northern Marianas",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestine",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PN: "Pitcairn Islands",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RE: "Reunion",
        RO: "Romania",
        RU: "Russia",
        RW: "Rwanda",
        BL: "Saint Barthélemy",
        SH: "Saint Helena, Ascension and Tristan da Cunha",
        KN: "Saint Kitts and Nevis",
        LC: "Saint Lucia",
        MF: "Saint Martin",
        PM: "Saint Pierre and Miquelon",
        VC: "Saint Vincent and the Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SX: "Sint Maarten",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        GS: "South Georgia and the South Sandwich Islands",
        KR: "South Korea",
        SS: "South Sudan",
        ES: "Spain",
        LK: "Sri Lanka",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syria",
        ST: "São Tomé e Príncipe",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania",
        TH: "Thailand",
        BS: "The Bahamas",
        TL: "Timor-Leste",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad and Tobago",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks and Caicos Islands",
        TV: "Tuvalu",
        VI: "US Virgin Islands",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UM: "United States Minor Outlying Islands",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VA: "Vatican City",
        VE: "Venezuela",
        VN: "Vietnam",
        WF: "Wallis and Futuna",
        EH: "Western Sahara",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        AX: "Åland"
    }
};

export default langEn;
